import jquery from "jquery";

// import external dependencies
import "alpinejs";
import slick from "slick-carousel";

// import local dependencies
import Router from "./util/Router";
import common from "./routes/common";
import home from "./routes/home";
import aboutUs from "./routes/about";

/** Setup jQuery $ variable for global use */
window.$ = jquery;

/** Populate Router instance with DOM routes */
const routes = new Router({
	// All pages
	common,
	// Home page
	home,
	// About Us page, note the change from about-us to aboutUs.
	aboutUs
});

// Load Events
jQuery(document).ready(() => routes.loadEvents());

jQuery(window).load(function() {
	// Sticky nav scroll functionality;
	let initScroll = $(window).scrollTop();
	let scrollTarget = $('.b-header');
	let targetHeight = scrollTarget.outerHeight();
	let scrolledHeight;
	let stickyTarget = $('.b-sticky-nav');
	let stickyPoint
	if ( stickyTarget.length ) {
		stickyPoint = stickyTarget.offset().top;
	}		

	if ( initScroll > targetHeight ) {
		scrollTarget.addClass('scrolled');
		scrolledHeight = scrollTarget.outerHeight();
		if ( stickyTarget.length ) {
			stickyTarget.css('top',scrolledHeight);
		}
	}

	if ( initScroll > stickyPoint && stickyTarget.length ) {
		stickyTarget.addClass('sticky');
	}

	$(window).on('scroll resize', function() {
	// $(window).scroll(function() {
		let currentScroll = $(window).scrollTop();
		scrolledHeight = scrollTarget.outerHeight();
		if ( currentScroll > targetHeight ) {
			scrollTarget.addClass('scrolled');
			scrolledHeight = scrollTarget.outerHeight();
			if ( stickyTarget.length ) {
				stickyTarget.css('top',scrolledHeight);
			}
		} else if ( currentScroll < targetHeight ) {
			scrollTarget.removeClass('scrolled');
		}
		if ( stickyTarget.length ) {
			if ( currentScroll > stickyPoint ) {
				stickyTarget.addClass('sticky');
			} else if ( currentScroll < stickyPoint ) {
				stickyTarget.removeClass('sticky');
			}
		}
	});
});