import "lity";
// import { fitVidsBlocks } from "./../util/fitVids";
// import { mobileMenuToggle } from "./../util/mobileMenu";

export default {
	init() {
		// JavaScript to be fired on all pages
		// ----- mobile menu 
		$('.b-header--toggle').on('click', function(){
			$(this).toggleClass('active');
			$('#mobile-menu').toggleClass('active');
		});

		$('#mobile-menu').on('click', '.menu-item-has-children > a', function(e){
			e.preventDefault();
			$(this).toggleClass('active');
			$(this).siblings('.sub-menu').slideToggle();
		});

		// ----- footer
		$('.b-footer--menu--mobile-title').on('click',function(){
			$(this).toggleClass('active');
			$(this).siblings('.b-footer--menu--links').slideToggle();
		});

		// ----- Set placeholder for the Store Locator
		$('#wpsl-search-input').attr('placeholder', 'Please enter your zip/postal code, city, or state/province');
	},
	finalize() {
		// JavaScript to be fired on all pages, after page specific JS is fired
	}
};
